import React from "react";
import Layout from "../components/shared/layout";
import PaymentStatus from "../components/shared/payment-status";

const OrderFailPage = () => {
  return (
    <Layout header={true} footer={true} isBackBtn={true}>
      <PaymentStatus message1="payment.failed.message1" error={true} />
    </Layout>
  )
}

export default OrderFailPage;
